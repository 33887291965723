import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-presitge-classes"></a>
    <h2>
Epic Prestige Classes
    </h2><a id="agent-retriever"></a>
    <h3>
AGENT RETRIEVER
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table><span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d6.
    <a id="agent-retriever-requirements"></a>
    <h6>
Requirements
    </h6>To qualify to become an agent retriever, a character must
fulfill all the following criteria.
    <p>
      <span style={{
        "fontWeight": "bold"
      }}>Alignment:</span> Any lawful.
    </p>
    <p>
      <span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="
skillsAll.html#gather-information" style={{
        "color": "rgb(87, 158, 182)"
      }}>Gather Information</a> 24 ranks,
      <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (the planes) 15 ranks.
    </p>
    <p>
      <span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a href="
featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track</a>.
    </p>
    <p style={{
      "fontWeight": "bold"
    }}>
Class Skills
    </p>
    <p>
The agent retriever's class skills (and the key ability for each
skill) are <a href="skillsAll.html#appraise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Appraise</a> (Int), <a href="
skillsAll.html#decipher-script" style={{
        "color": "rgb(87, 158, 182)"
      }}>Decipher Script</a> (Int), <a href="
skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> (Cha), <a href="
skillsAll.html#forgery" style={{
        "color": "rgb(87, 158, 182)"
      }}>Forgery</a> (Int), <a href="
skillsAll.html#gather-information" style={{
        "color": "rgb(87, 158, 182)"
      }}>Gather Information</a> (Cha), <a href="
skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (arcana) (Int),
Knowledge (geography) (Int), Knowledge (history) (Int), Knowledge
(local) (Int), Knowledge (the planes) (Int), <a href="
skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> (Wis), <a href="
skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> (Int), and <a href="
skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
      <p>{`(Wis), `}<a href="skillsAll.html#survival" style={{
          "color": "rgb(87, 158, 182)"
        }}>{`Survival`}</a>{` (Wis).`}</p>
    </p>
    <p>
      <span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Level:</span> 6 + Int modifier.
    </p><span style={{
      "fontWeight": "bold"
    }}>
      <a id="table-the-agent-retriever"></a>Table: The Agent
Retriever
    </span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>
Level
          </th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>
Special
          </th>
          <td style={{
            "verticalAlign": "top"
          }}>
            <span style={{
              "fontWeight": "bold"
            }}>Spells per Day</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>
1st
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>
            <span style={{
              "fontStyle": "italic"
            }}>Uncanny location</span>,
tracking bonus +10
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
+1 level of existing class
          </td>
        </tr>
        <tr>
          <td>
2nd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>
            <span style={{
              "fontStyle": "italic"
            }}>Plane shift</span> 1/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
+1 level of existing class
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <p>{`3rd`}</p>
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>
            <span style={{
              "fontStyle": "italic"
            }}>Force sphere</span> 1/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
+1 level of existing class
          </td>
        </tr>
        <tr>
          <td>
4th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>
            <span style={{
              "fontStyle": "italic"
            }}>Ethereal jaunt</span>
1/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
+1 level of existing class
          </td>
        </tr>
        <tr className="odd-row">
          <td>
5th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>
            <p>{`Bonus feat`}</p>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
+1 level of existing class
          </td>
        </tr>
        <tr>
          <td>
6th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>
Tracking bonus +20
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
+1 level of existing class
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <p>{`7th`}</p>
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>
            <span style={{
              "fontStyle": "italic"
            }}>Plane shift</span> 2/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
+1 level of existing class
          </td>
        </tr>
        <tr>
          <td>
8th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>
            <span style={{
              "fontStyle": "italic"
            }}>Force sphere</span> 2/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
+1 level of existing class
          </td>
        </tr>
        <tr className="odd-row">
          <td>
9th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>
            <span style={{
              "fontStyle": "italic"
            }}>Ethereal jaunt</span>
2/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
+1 level of existing class
          </td>
        </tr>
        <tr>
          <td className="last-row">
            <p>{`10th`}</p>
          </td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>
Bonus feat
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
+1 level of existing class
          </td>
        </tr>
      </tbody>
    </table>
    <a id="agent-retriever-class-features"></a>
    <h6>
Class Features
    </h6>The following are class features of the agent retriever
prestige class.
    <p>
      <span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor
Proficiency:</span> An agent retriever gains no proficiency with
any weapons, armor, or shields.
    </p>
    <p>
      <span style={{
        "fontWeight": "bold"
      }}>Spells per Day/Spells
Known:</span> At each agent retriever level, the character gains
new spells per day (and spells known, if applicable) as if he or
she had also gained a level in a spellcasting class to which he or
she belonged before adding the prestige class level. If already an
epic spellcaster, the character gains only the benefit noted under
the Spells entry for that epic class. He or she does not, however,
gain any other benefit a character of that class would have
gained. If the character had more than one spellcasting class
before becoming an agent retriever, the player must decide to
which class to add the new level for the purpose of determining
spells per day.
    </p><a id="agent-retriever-uncanny-location"></a>
    <p>
      <span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Uncanny
Location (Sp):</span> When a agent retriever spends one day
attuning him or herself to a person or object he or she is
seeking, he or she automatically determines that person&#8217;s or
item&#8217;s location as the discern location spell. Once he or she has
established a sense of the location, the agent retriever can
maintain this uncanny link even if the target moves, but only so
long as he or she hunts down this person or item to the exclusion
of all other pursuits. If he or she ever turns aside to undertake
a second pursuit, the uncanny location ends, and the agent
retriever must spend another day to reattune him or herself to the
target.
    </p>
    <p>
      <span style={{
        "fontWeight": "bold"
      }}>Tracking Bonus (Ex):</span> The
agent retriever gains a +10 insight bonus on Wilderness Lore
checks to track the quarry. This bonus increases by +10 every five
levels thereafter.
    </p>
    <p>
      <span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Plane Shift
(Sp):</span> The agent retriever can use plane shift as a
14th-level caster once per day starting at 2nd level, plus one
additional time per day every five levels thereafter.
    </p>
    <p>
      <span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Force Sphere
(Sp):</span> The agent retriever gains the ability to call forth a
force sphere. The agent retriever can attempt to enclose any
creature or object he or she can see within 30 feet. The target is
allowed a Reflex saving throw (DC 20 + 1/2 the class level of the
agent retriever + the agent retriever&#8217;s Dexterity modifier). Those
who fail are then encapsulated in a sphere of force with a radius
of up to 50 feet (the sphere is only as large as it needs to be,
up to its maximum radius). Those trapped inside cannot escape
except with methods that can bypass or destroy a wall of force.
The sphere persists as long as the agent retriever desires, up to
a maximum of seven days. A captured target does not count toward
the capacity of the agent retriever�s plane shift ability, and the
agent retriever can plane shift with the target despite the
presence of the force sphere. The agent retriever can use this
power once per day at 3rd level, plus one additional time per day
every five levels thereafter.
    </p>
    <p>
      <span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Ethereal
Jaunt (Sp):</span> The agent retriever can use ethereal jaunt as a
14th-level caster once per day at 4th level, plus one additional
time per day every five levels thereafter.
    </p>
    <p>
      <span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The agent
retriever gets a bonus feat at 5th level and an additional bonus
feat every five levels thereafter. These bonus feats must be
selected from the following list: Epic Endurance, Epic Prowess,
Epic Speed, Epic Toughness, Epic Weapon Focus, Fast Healing,
Improved Combat Casting, Improved Spell Capacity, Legendary
Climber, Legendary Rider, Legendary Tracker, Perfect Health,
Permanent Emanation, Spontaneous Spell, Storm of Throws, Swarm of
Arrows, and Uncanny Accuracy.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      